
    import Vue from 'vue';
    import Field from '@/components/Field.vue';

    import RCP from '@/api/RCP.ts';


    import Validator from '@/helpers/validator';

    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'editor',
        data() {
            return {
                template: {
                    name: 'Новый документ',
                    string_id: '',
                    description: ''
                },
                templateRules: {
                    name: 'required',
                    string_id: 'required'
                },
                errors: {
                    name: '',
                    string_id: ''
                },
                request: false,
                formRequest: false
            };
        },
        methods: {
            validate(submit = true) {
                if (!this.request) {
                    const validate = new Validator(this.template, this.templateRules).make();

                    this.errors = validate.errors;

                    if (validate.isValid && submit) {
                        this.createTemplate();
                    } else {
                        this.formRequest = true;
                    }
                }
            },
            createTemplate() {
                this.request = true;

                RCP({
                    method: 'App.Template.set',
                    params: {
                        attr: {
                            ...this.template,
                            app_id: 1
                        }
                    },
                    id: 'createTemplate'
                }).then((result: any) => {
                    if (result.item) {
                        this.$store.commit('template/set', result.item);
                        this.$router.push('/template/' + result.item.id);
                    }

                    this.request = false;
                }).catch((error: ErrorResponse) => {

                    if (error.show) {
                        alert('Ошибка : ' + error.message);
                    }

                    this.request = false;
                });
            }
        },
        watch: {
            formRequest(val) {
                if (val) {
                    this.$watch('template', () => {
                        this.validate(false);
                    }, {
                        deep: true
                    });
                }
            }
        },
        components: {
            Field
        }
    });
